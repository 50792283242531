@use "sass:math";

$html-font-size: 16px;

@function stripUnit($value) {
    @return math.div($value, $value * 0 + 1);
}

@function rem($pxValue) {
    @return #{math.div(stripUnit($pxValue), stripUnit($html-font-size))}rem;
}

//Color Vars

$colorwhite: #fff;
$colorblack: #000;
$colorsocial:#d2cbe4;
$colorprimary:#3D246B;
$colorlightprimary:#D1CAE3;
$color-primary-100:#AB8CE3;
$colorsidebar:#f7f2f9;
$colorsecondary:#75c7ac;
$colorcontent: #374254;
$border-color: #F7F2F9;
$field-bg: #eef0f2;
$colorred: #f70c0c;
$heading-color: #32383F;
$light-purple:#EAD2F4;


//Font Family

$font-secondary: 'Share', cursive;