body {
    color: #3C3935;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    font-size: rem(16);
    background: $colorwhite;
}

input[type=text], 
input[type=email], 
input[type=tel],
input[type=number],
// input[type=password], 
select, textarea,
.ant-input-password{
// .ant-picker,
// .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selector:focus{
    padding: rem(10) rem(14);
    border-radius: 3px;
    font-weight: 400;
    border: 0;
    background-color: $colorwhite !important;
    border: 1px solid #DEDAE2;
    width: 100%;
    

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #70797D;
        font-size: rem(14);
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: #70797D;
        font-size: rem(14);
      }
    &:-ms-input-placeholder { /* IE 10+ */
        color: #70797D;
        font-size: rem(14);
      }
    &:-moz-placeholder { /* Firefox 18- */
        color: #70797D;
        font-size: rem(14);
    }
}

.ant-btn[disabled], 
    .ant-btn[disabled]:hover, 
    .ant-btn[disabled]:focus, 
    .ant-btn[disabled]:active{
        cursor: not-allowed
    }

.ant-select-single .ant-select-selector .ant-select-selection-search-input{
    padding: rem(10) rem(14);
    border-radius: 3px;
    font-weight: 400;
    border: 0;
    background-color: $colorwhite !important;
    border: 1px solid #DEDAE2;
    width: 100%;
}

.ant-tag{
    padding: rem(4) rem(8);
    background-color: $colorwhite;
    margin-bottom: rem(4);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    padding: rem(6) rem(14);
    border-radius: 3px;
    font-weight: 600;
    border: 0;
    background-color: $colorwhite !important;
    border: 1px solid #DEDAE2;
    width: 100%;
    height: auto;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector  .ant-select-selection-search-input{
    background-color: transparent !important;
}

.ant-picker{
    cursor: pointer;
}

.ant-picker{
    padding: rem(2) rem(6) rem(2) rem(0);
    border-radius: 3px;
    font-weight: 400;
    border: 1px solid #DEDAE2;
    // background-color: $field-bg !important;
    width: 100%;

    .ant-picker-input > input{
        line-height: normal;
        padding: rem(10) rem(14);

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #70797D;
            font-size: rem(14);
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #70797D;
            font-size: rem(14);
          }
        &:-ms-input-placeholder { /* IE 10+ */
            color: #70797D;
            font-size: rem(14);
          }
        &:-moz-placeholder { /* Firefox 18- */
            color: #70797D;
            font-size: rem(14);
        }
    }
}


a{
    color: $colorprimary;
    &:hover{
        opacity: 0.9;
        color: $colorprimary;
    }
}



//Generic Style

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before{
    display: none !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after{
    display: inline-block;
    margin-right: rem(5);
    margin-left: rem(5);
    color: $colorprimary;
    font-size: rem(14);
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '';
}

label.ant-form-item-required:after{
    display: inline-block;
    margin-right: rem(5);
    margin-left: rem(5);
    color: #ff4d4f;
    font-size: rem(14);
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.form-group{
    .ant-form-item-label{
        label{
            color: $colorcontent;
        }
    }
}

.ant-form-item-has-error{
    input[type=text], 
    input[type=email], 
    input[type=tel], 
    select, textarea, 
    .ant-input-password,
    .ant-picker,
    .ant-select-selector{
        border: 1px solid $colorred !important;
    }
}


.ant-btn{
    font-size: rem(12);
    color: #333;
    border-radius: 3px;
    border: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    min-height: rem(38);

    &:hover, &:focus{
        opacity: 0.85;
        outline: none;
    }

    &:where(.css-mxhywb).ant-btn-primary:not(:disabled):hover,
    &:where(.css-dev-only-do-not-override-mxhywb).ant-btn-primary:not(:disabled):hover {
        background-color: $colorprimary !important;

    }
    &:where(.css-mxhywb).ant-btn-secondary:not(:disabled):hover,
    &:where(.css-dev-only-do-not-override-mxhywb).ant-btn-secondary:not(:disabled):hover {
        background-color: $colorsecondary !important;
    }
    &:where(.css-mxhywb).ant-btn-default,
    &:where(.css-1qhpsh8).ant-btn-default, 
    &:where(.css-dev-only-do-not-override-mxhywb).ant-btn-default {
        background-color: transparent !important;
        background: transparent !important;
        &:hover{
            color: $colorprimary !important;
        }
    }
}

.ant-btn-primary{
    background: $colorprimary !important;
    color: $colorwhite !important;
    &:hover, &:focus{
        background: $colorprimary !important;
    }
    &:disabled{
        background: $colorprimary !important;
        color: $colorwhite !important;
    }
}

.ant-btn-light-primary{
    background: $colorlightprimary !important;
    color: $colorprimary !important;
    &:hover, &:focus{
        background: $colorlightprimary !important;
    }
}

.ant-btn-secondary{
    background: $colorsecondary !important;
    color: $colorwhite !important;
    border-radius: rem(20); 
    &:hover, &:focus{
        background: $colorsecondary !important;
        color: $colorwhite !important;
    }
}

.ant-btn-reject{
    background: $colorwhite!important;
    border: rem(2) solid $colorred;
    color: $colorred;
    border-radius: rem(20); 
    &:hover, &:focus{
        background: $colorwhite !important;
        border: rem(2) solid rgb(215, 30, 30);
        color: $colorred;
    }
}

.ant-btn-social{
    background: $colorsocial;
    color: $colorprimary;
    &:hover, &:focus{
        background:$colorsocial;
    }
}

.ant-btn-white{
    background: $colorwhite ;
    color: $colorprimary !important;
    border: lightgray 1px solid !important;
    &:hover, &:focus{
        background:$colorwhite;
    }
}

.ant-btn-border-primary {
    border: rem(1) solid $colorprimary !important;
    background-color: white;
    color: $colorprimary;
    border-radius: rem(20) !important;

    &:hover,
    &:focus {
        background-color: white !important;
        border: rem(1) solid $colorprimary;
    }
}

//Loader

.ant-space {
    &.loader{
        display: inline-flex;
        background: rgba(255,255,255,0.6);
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        justify-content: center;
        z-index: 9999;

        .ant-space-item{
            .ant-spin-spinning{
                .ant-spin-dot-spin{
                    transform: rotate(0deg);
                    -webkit-animation: none;
                    animation: none;
                    .ant-spin-dot-item{
                        display: none;
                    }
                    &::before{
                        content: url(../images/spinner-o.gif);
                    }
                }
            }
        }
    }
}

.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: rem(20) rem(45) !important;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 100% !important;
    outline: none;
}

.ant-layout{
    background: $colorwhite;
}

.main-head{
    margin-top: 50px;
}
.header{
    color: $colorprimary;
}

.ant-radio-button-wrapper{
    background-color: $colorwhite;
    color: $colorprimary;
    &:hover{
        color: $colorwhite;
        background-color: $color-primary-100;
    }
}

.color-primary{
    color: $colorprimary;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)  {

    border-color: $colorprimary !important;
    background: $colorprimary !important;
    &:hover{
        background: $color-primary-100 !important;
        border-color: $colorprimary;
    }
    &::before{
        background-color: $colorprimary !important;
    }
}


.ant-checkbox-checked .ant-checkbox-inner {
    
    color: $colorprimary !important;
    background-color: $colorprimary !important;
    border-color:  $colorprimary !important;
    
}

//Pagination

.ant-pagination{
    .ant-pagination-item{
        min-width: rem(28);
        height: rem(28);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &:hover{
            border-color: $colorprimary;
            a{
                color: $colorprimary;
            }
        }

        &.ant-pagination-item-active{
            &:hover{
                a{
                    color: $colorwhite;
                }
            }
        }
    }
    .ant-pagination-prev, 
    .ant-pagination-next {
        min-width: rem(28);
        height: rem(28);
        .ant-pagination-item-link{
            border: 0;
            font-size: rem(12);
            color: #8E98A1;
            font-weight: 500;
            background: transparent;
        }
        &:hover{
            opacity: 0.85;
        }
    }

    .ant-pagination-item-active {
        font-weight: 500;
        background: $colorprimary;
        border: 0;
        font-size: rem(12);
        border-radius: 4px;
        a{
            color: $colorwhite;
        }
        &:hover{
            opacity: 0.85;
        }
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #eee; /* or add it to the track */
}
  
::-webkit-scrollbar-thumb {
    background: $colorsecondary
}

.not-found-style{
    height: 100%;
}

@media print {
    body {-webkit-print-color-adjust: exact;}
}


// if images not found then use this styling for preview
.no-priview-view{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colorprimary;
    font-weight: bold;
    border-top-left-radius: rem(10);
    border-bottom-left-radius: rem(10);
    // border: 1px $colorprimary solid;
  } 
