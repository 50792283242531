@import "../../assets/sass/variables";



.login-wrapper {
    overflow-y: auto;
    input[type=text],
    input[type=email],
    select,
    textarea,
    .ant-input-password {
        padding: rem(10) rem(14);

        .ant-input-password-icon {
            color: $colorprimary;
        }

        input[type=text] {
            padding: 0;
            height: auto;
        }

    }


    .ant-btn {
        font-size: rem(12);
        // color: white;
        border-radius: rem(20);
        border: 0;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        min-height: rem(38);
        width: 100%;

        &:hover,
        &:focus {
            opacity: 0.85;
            outline: none;
        }

        &:where(.css-mxhywb).ant-btn-primary:not(:disabled):hover,
        &:where(.css-dev-only-do-not-override-mxhywb).ant-btn-primary:not(:disabled):hover {
            background-color: $colorprimary;
        }

        &:where(.css-dev-only-do-not-override-mxhywb).ant-btn-secondary:not(:disabled):hover {
            background-color: $colorsecondary;
        }
    }

    .ant-form {
        input[type=password] {
            padding: 0;
            height: auto;
        }
    }

    // .ant-form-item-label label {
    //     color: $colorprimary; /* Replace 'red' with the desired color */
    //   }

    .login-text {
        color: $colorprimary;
        font-size: rem(50);
    }





    .form-row {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }



    .login-pic {
        position: fixed;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        img {
            margin: 0;
            height: 70%;
            width: 45%;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transform-origin: right 0;
            transform-origin: top;
            background-size: contain;
            margin-bottom: rem(60);
           
        }
    }

    .login-form-wrapper {
        width: rem(360);

        .ant-form-item {
            margin-bottom: rem(16);
        }

        .title {
            margin-bottom: rem(14);
            font-size: rem(25);
        }

        .desc {
            margin-bottom: rem(20);
            font-size: rem(12);
            color: $colorcontent;
        }

    }

    .app-logo {
        // width: 120%;
        // text-align: center;
        margin-bottom: rem(25);

        img {
            max-width: rem(200);
        }
    }

    .login-form-row {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }


    .needHelpTxt {
        margin-top: rem(30);
        margin-bottom: 0;
        color: $colorcontent;
        font-size: rem(14);

        a {
            margin-left: rem(5);
            color: $colorsecondary;
        }
    }

    .forget-password-link {
        color: $colorprimary;
        font-size: rem(14);
        font-weight: bold;
    }

    .ant-alert {
        width: 100%;
        margin-bottom: rem(20) !important;
    }

    .link-text {
        font-weight: lighter;
        text-decoration: underline;
        color: $colorprimary;
        font-weight: bold;
    }

    .back-to-login {
        font-size: rem(17);
    }

    .space-line {
        border: $border-color 1px solid;
    }

    .signup_profile_image {
        height: rem(200);
        width: rem(200);
        border-radius: rem(100);
    }
}

.reset-wrapper {
    background-color: #8b7ca6;
    height: 100vh;
    width: 100%;

    .overlay-full-width {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 67%;
        height: 70%;
        background-color: $colorwhite;
        /* Overlay background color and opacity */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        /* Shadow properties */
        z-index: 1;
        /* Ensure the overlay is on top of other elements */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    input[type=text],
    input[type=email],
    input[type=tel],
    select,
    textarea,
    .ant-input-password {
        padding: rem(10) rem(14);

        .ant-input-password-icon {
            color: $colorprimary;
        }

        input[type=text] {
            padding: 0;
            height: auto;
        }

    }

    .ant-btn {
        font-size: rem(12);
        // color: white;
        border-radius: rem(20);
        border: 0;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 600;
        min-height: rem(38);
        width: 100%;

        &:hover,
        &:focus {
            opacity: 0.85;
            outline: none;
        }

        &:where(.css-dev-only-do-not-override-mxhywb).ant-btn-primary:not(:disabled):hover {
            background-color: $colorprimary;
        }

        &:where(.css-dev-only-do-not-override-mxhywb).ant-btn-secondary:not(:disabled):hover {
            background-color: $colorsecondary;
        }
    }

    .ant-form {
        input[type=password] {
            padding: 0;
            height: auto;
        }
    }

    .login-text {
        color: $colorprimary;
        font-size: rem(30);
    }

    .login-form-wrapper {
        width: 100%;

        .ant-form-item {
            margin-bottom: rem(16);
        }

        .title {
            margin-bottom: rem(14);
            font-size: rem(25);
        }

        .desc {
            margin-bottom: rem(20);
            font-size: rem(12);
            color: $colorcontent;
        }

    }

    .center-wrap {
        display: flex;
        align-items: center;
        text-align: center;
    }

    .icon-confirmation {
        margin-top: rem(60);
        font-size: rem(60);
        color: #71C7AA;
    }
}


.background-pic {
    background-image: url('../../assets/images/login-vector.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 35vh;
    width: 50%;
    margin: 0;

    img {
        margin: 0;
        margin-left: rem(58);
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: right 0;
        transform-origin: top;
    }
}

// QR screen style

.qr-screen-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .qr-image {
        height: rem(250);
        width: rem(250);
    }
}

// OTP screen style

.otp-screen-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

}



@media (max-width: 767px) {
    .login-form-row {
        .form-row {
            margin: 0 rem(30);
        }
    }
}