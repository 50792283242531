@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?9zek');
    src:  url('../fonts/icomoon.eot?9zek#iefix') format('embedded-opentype'),
      url('../fonts/icomoon.ttf?9zek') format('truetype'),
      url('../fonts/icomoon.woff?9zek') format('woff'),
      url('../fonts/icomoon.svg?9zek#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-send:before {
    content: "\e902";
  }
  .icon-back:before {
    content: "\e903";
  }
  .icon-lock:before {
    content: "\e904";
  }
  .icon-facebook:before {
    content: "\e900";
    color: #3d246b;
  }
  .icon-Google:before {
    content: "\e901";
    color: #3d246b;
  }
  